import {InteractionRequiredAuthError, PublicClientApplication} from '@azure/msal-browser';
import {AuthConfig} from './authConfig';
import {Router} from '@vaadin/router';
import {GraphAPI} from './graph';
import {GraphConfig} from './graphConfig';

export const msalInstance = new PublicClientApplication(AuthConfig.msalConfig);

export class AuthRedirect {
  static async initialize() {
    try {
      await msalInstance.initialize();
      const response = await msalInstance.handleRedirectPromise();
      if (response) {
        console.info('User logged in, redirecting...');
        Router.go('/chat');
      } else {
        console.info('No user logged in or waiting for login response.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  static async acquireToken() {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const tokenResponse = await msalInstance.acquireTokenSilent({
          ...AuthConfig.loginRequest,
          account: accounts[0],
        });
        console.info('Token acquired silently:', tokenResponse.accessToken);
        return tokenResponse.accessToken;
      }
      throw new Error('No accounts found');
    } catch (error) {
      console.error('Failed acquiring token silently', error);
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(AuthConfig.loginRequest);
      }
      throw error;
    }
  }

  static login() {
    msalInstance.loginRedirect(AuthConfig.loginRequest).catch((error) => {
      console.error('Login failed', error);
    });
  }

  static logout() {
    msalInstance.logoutRedirect().catch((error) => {
      console.error('Logout failed', error);
    });
  }

  static getProfile(token: string) {
    GraphAPI.callMSGraph(GraphConfig.graphMeEndpoint, token, (response) => {
      console.info('Graph API response:', response);
    });
  }
}

AuthRedirect.initialize();
